<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Parameter
                </CardTitle>                
            </CardHeader>
            <CardBody>
                N/A
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Aus Bestellreferenzen abgeleitete EKs
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={outputDocument?.items ?? []} 
                    columns={resultColumns} 
                    showDownloadButtonCsv="true"
                    showPageSizeDropDown="true" />
            </CardBody>
        </Card>
    {/if}
    </svelte:fragment>
</Base>

<script>
 
    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    import { tableColumns } from "../../orderreferences/OrderReferenceDetails.svelte";

    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

    let resultColumns = [
        { 
            displayName: 'EAN', 
            propertyName: 'ean' 
        },
        { 
            displayName: 'Zustand', 
            propertyName: 'itemCondition' 
        },
        { 
            displayName: 'EK', 
            propertyName: 'ek', 
            format: 'NUMBER_2_DECIMALS'
        }
    ];


</script>