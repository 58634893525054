<Card class="mb-3">
    <CardHeader>
        Verkäufe
    </CardHeader>
    <CardBody>
        <TableWithPaginationAndSort responsive 
            rows={orderRows} 
            columns={tableColumns} 
            sortDirection="desc" 
            sortByColumn="extOrderDate">
            <div slot="custom-slot-1" let:row={row} let:column={column}>
                { row[column.propertyName] ? salesChannelToText(row[column.propertyName]) : "-" }
            </div>            
        </TableWithPaginationAndSort>                    
    </CardBody>
</Card> 

<script>
    
    import { 
        concat, 
        orderBy 
    } from 'lodash';

    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { salesChannelToText } from "@/utils/textenums";

    import {
        Card,
        CardBody,
        CardHeader
    } from '@sveltestrap/sveltestrap';

    export let queryResultData;

    let orderRows;
    $: {
        let a = (queryResultData?.amazonSalesDetails?.items || [])
                .map(s => ({
                    salesChannel: queryResultData?.amazonSalesDetails?.salesChannel,
                    extItemId: s.extItemId,
                    extOrderId: s.extOrderReference,
                    extOrderDate: s.extOrderDate,
                    itemCondition: s.itemCondition,
                    subCondition: s.subCondition,
                    quantity: s.saleAmount,
                    vk: s.saleUnitPrice
                }));        
        let b = (queryResultData?.cheabooSalesDetails?.items || [])
                .map(s => ({
                    salesChannel: queryResultData?.cheabooSalesDetails?.salesChannel,
                    extItemId: s.extItemId,
                    extOrderId: s.extOrderReference,
                    extOrderDate: s.extOrderDate,                    
                    itemCondition: s.itemCondition,
                    subCondition: s.subCondition,
                    quantity: s.saleAmount,
                    vk: s.saleUnitPrice
                }));
        let c = (queryResultData?.buchhandelSalesDetails?.items || [])
            .map(s => ({
                salesChannel: queryResultData?.buchhandelSalesDetails?.salesChannel,
                extItemId: s.extItemId,
                extOrderId: s.extOrderReference,
                extOrderDate: s.extOrderDate,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.saleAmount,
                vk: s.saleUnitPrice
            })); 
        let d = (queryResultData?.trade123SalesDetails?.items || [])
            .map(s => ({
                salesChannel: queryResultData?.trade123SalesDetails?.salesChannel,
                extItemId: s.extItemId,
                extOrderId: s.extOrderReference,
                extOrderDate: s.extOrderDate,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.saleAmount,
                vk: s.saleUnitPrice
            }));    
        let e = (queryResultData?.warenweltSalesDetails?.items || [])
            .map(s => ({
                salesChannel: queryResultData?.warenweltSalesDetails?.salesChannel,
                extItemId: s.extItemId,
                extOrderId: s.extOrderReference,
                extOrderDate: s.extOrderDate,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.saleAmount,
                vk: s.saleUnitPrice
            }));                              
        let x = concat(a, b, c, d, e);
        orderRows = orderBy(x, ["createdAt", "extItemId"], ["desc", "asc"]);
    }

    let tableColumns = [
        { displayName: 'Verkaufskanal', propertyName: 'salesChannel', customSlot: '1' },
        { displayName: 'Art.Nr.', propertyName: 'extItemId' },
        { displayName: 'ItemCondition', propertyName: 'itemCondition' },
        { displayName: 'SubCondition', propertyName: 'subCondition' },        
        { displayName: 'KaufDatum', propertyName: 'extOrderDate', format: 'DATETIME' },
        { displayName: 'Menge', propertyName: 'quantity', format: 'NUMBER_0_DECIMALS' },
        { displayName: 'UnitPrice', propertyName: 'vk', format: 'NUMBER_2_DECIMALS' },
        { displayName: 'Best.Nr.', propertyName: 'extOrderId' }
    ];  

</script>