<Base title="Produktabfrage">
    <svelte:fragment slot="main">

        <Container fluid class="p-0">
            <Row>
                <Col lg="8" class="mb-3">
                    <ProduktKopfDaten {queryResultData} class="h-100"/>
                </Col>
                <Col lg="4" class="mb-3 d-flex align-items-stretch">
                    <AbsatzHistogrammTabelle {queryResultData} class="h-100" />
                </Col>
            </Row>
        </Container>

        <Container fluid class="p-0">
            <Row>
                <Col lg="6">
                    <KKPreisTabelle {queryResultData} newOrUsed="new" on:data-modified={loadData} />
                </Col>
                <Col lg="6">        
                    <KKPreisTabelle {queryResultData} newOrUsed="used" on:data-modified={loadData} />
                </Col>
            </Row>
        </Container>

        <Container fluid class="p-0">
            <Row>
                <Col lg="6">
                    <KKPreisChart {queryResultData} newOrUsed="new" />
                </Col>
                <Col lg="6">
                    <KKPreisChart {queryResultData} newOrUsed="used" />
                </Col>
            </Row>
        </Container>

        <Container fluid class="p-0">
            <Row>
                <Col lg="6" class="mb-3">
                    <AmazonPreisTabelle {queryResultData} newOrUsed="new" />
                </Col>
                <Col lg="6" class="mb-3">
                    <AmazonPreisTabelle {queryResultData} newOrUsed="used" />
                </Col>
            </Row>
        </Container>

        <Container fluid class="p-0">
            <Row>
                <Col>
                    <AmazonPreisTabelle {queryResultData} newOrUsed="mws" />
                </Col>
            </Row>
        </Container>

        <AmazonVerkaufsrangChart {queryResultData} />          

        <AmazonLagerTabelle {queryResultData} /> 
        
        <VerkaufTabelle {queryResultData} />          

        <FallbackTabelle ean={params.id} />
        
    </svelte:fragment>
</Base>

<script>

    import { getProduct } from "@/clients/apdbClient";

    import Base from "../Base.svelte";

    import KKPreisTabelle from "./_KKPreisTabelle.svelte";
    import KKPreisChart from "./_KKPreisChart.svelte";
    import AmazonPreisTabelle from "./_AmazonPreisTabelle.svelte"; 
    import AmazonVerkaufsrangChart from "./_AmazonVerkaufsrangChart.svelte";
    import AmazonLagerTabelle from "./_LagerTabelle.svelte"; 
    import AbsatzHistogrammTabelle from "./_AbsatzHistogrammTabelle.svelte"; 
    import ProduktKopfDaten from "./_ProduktKopfDaten.svelte";
    import VerkaufTabelle from "./_VerkaufTabelle.svelte"; 
    import FallbackTabelle from "./_FallbackTabelle.svelte"; 

    import { Col, Container, Row } from '@sveltestrap/sveltestrap';

    export let params = {};
    
    let queryResultData = null;
    let queryResultStatus = null;

    $: {
        params.id;
        loadData();
    }

    async function loadData() {
        await getProduct(params.id)
            .then(p => {
                queryResultData = p.queryResultData;
                queryResultStatus = p.queryResultStatus;
            });
    }

</script>