<Base>
    <svelte:fragment slot="main">
        {#if queryResultData}
            <Card>
                <CardHeader>
                    <CardTitle>
                        Bestellreferenzdetails
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <TableWithPaginationAndSort responsive 
                        rows={(queryResultData?.items || [])} 
                        columns={tableColumns} 
                        sortDirection="desc" 
                        sortByColumn="submitted" 
                        showPageSizeDropDown="{true}"
                        showDownloadButtonXlsx="{true}"
                        filterGlobalEnabled="{true}"
                        id="order-reference-details"
                        on:row-click={navigateToItem} />
                </CardBody>
            </Card>
        {/if}
    </svelte:fragment>
</Base>

<script lang="ts">

    import { onMount } from 'svelte';    
    import { push } from 'svelte-spa-router'
    import Base from "../Base.svelte";
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    import { Card, CardBody, CardHeader, CardTitle, CardSubtitle } from '@sveltestrap/sveltestrap';

    import { orderReferencesApi } from "@/clients/apdbClient";

    export let params = { id };

    onMount(async () => {
        orderReferencesApi.getOrderReferenceDetails({ orderReferenceGroupId: params.id })
            .then(p => {
                queryResultData = p.queryResultData;
                queryResultStatus = p.queryResultStatus;
            });
    });

    function navigateToItem(onRowClickEvent) {
        const { ean } = onRowClickEvent?.detail?.row;
        if (ean) {
            push(`/products/${ean}`);
        }
    }

    let queryResultData = null;
    let queryResultStatus = null;

</script>

<script context="module" lang="ts">
    
    const colorEinkauf = "#f9f9f9"
    const colorVerkaufAmazon = "#bbee99"
    const colorVerkaufJager = "#ffff99"
    const colorDisposal = "#fec1c1"

    export const tableColumns = [
        { 
            displayName: 'Referenz Gruppe', 
            propertyName: 'orderReferenceGroupName',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'Referenz', 
            propertyName: 'orderReferenceName',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'Lieferant', 
            propertyName: 'orderReferenceLieferant',
            width: '160px',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'Zuordnung ab', 
            propertyName: 'orderReferenceStartDate',
            format: 'DATETIME',
            width: '160px',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'EAN', 
            propertyName: 'ean',
            width: '160px',
            style: `background-color: #fefefe;` 
        },
        {   displayName: 'Zustand', 
            propertyName: 'itemCondition',
            style: `background-color: #fefefe;`
        },
        { 
            displayName: 'Menge Einkauf', 
            propertyName: 'einkaufTotalQuantity', 
            format: 'NUMBER_0_DECIMALS',
            width: '100px',
            style: `background-color: ${colorEinkauf};` 
        }, 
        { 
            displayName: 'EK', 
            propertyName: 'einkaufUnitPurchaseAmount', 
            format: 'NUMBER_2_DECIMALS',
            width: '100px',
            style: `background-color: ${colorEinkauf};` 
        }, 
        { 
            displayName: ' Kosten EK', 
            propertyName: 'einkaufTotalCostsAmount', 
            format: 'NUMBER_2_DECIMALS',
            width: '100px',
            style: `background-color: ${colorEinkauf};` 
        },                         
        { 
            displayName: 'Σ Kosten EK', 
            propertyName: 'einkaufSumTotalCostsAmount', 
            format: 'NUMBER_2_DECIMALS',  
            width: '100px',
            style: `background-color: ${colorEinkauf};` 
        },   

        { 
            displayName: 'Menge Verkauf Amazon Zustand NEU', 
            propertyName: 'verkaufAmazonNewQuantity', 
            format: 'NUMBER_0_DECIMALS',
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },    
        { 
            displayName: 'Menge Verkauf Amazon Zustand Gebraucht', 
            propertyName: 'verkaufAmazonUsedQuantity', 
            format: 'NUMBER_0_DECIMALS',
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },                  
        { 
            displayName: 'Umsatz (brutto) Amazon', 
            propertyName: 'verkaufAmazonSalesAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },               
        { 
            displayName: 'Kosten Verkauf Amazon', 
            propertyName: 'verkaufAmazonCostsAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },  
        { 
            displayName: 'Ergebnis Amazon', 
            propertyName: 'verkaufAmazonResultAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufAmazon};` 
        },  

        { 
            displayName: 'Menge Verkauf Jager Zustand NEU', 
            propertyName: 'verkaufJagerNewQuantity', 
            format: 'NUMBER_0_DECIMALS',
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },    
        { 
            displayName: 'Menge Verkauf Jager Zustand Gebraucht', 
            propertyName: 'verkaufJagerUsedQuantity', 
            format: 'NUMBER_0_DECIMALS',
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },                  
        { 
            displayName: 'Umsatz (brutto) Jager', 
            propertyName: 'verkaufJagerSalesAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },               
        { 
            displayName: 'Kosten Verkauf Jager', 
            propertyName: 'verkaufJagerCostsAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },  
        { 
            displayName: 'Ergebnis Jager', 
            propertyName: 'verkaufJagerResultAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorVerkaufJager};` 
        },

        { 
            displayName: 'Menge Disposal', 
            propertyName: 'disposalTotalQuantity', 
            format: 'NUMBER_9_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorDisposal};` 
        },  
        { 
            displayName: 'Kosten Disposal', 
            propertyName: 'disposalTotalAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: ${colorDisposal};` 
        },

        { 
            displayName: 'GuV', 
            propertyName: 'guvTotalAmount', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: #fefefe;` 
        },               
        { 
            displayName: 'GuV bereinigt', 
            propertyName: 'guvTotalAmountAdjusted', 
            format: 'NUMBER_2_DECIMALS', 
            width: '100px',
            style: `background-color: #fefefe;` 
        } 

    ];

</script>