<Card class="mb-3">
    <CardHeader>
        <Container class="p-0">
            <Row>
                <Col>
                    <div>
                        KK Preise { newOrUsed == 'new' ? 'NEU' : newOrUsed == 'used' ? "GEBRAUCHT" : "" }
                    </div>
                </Col>
                <Col xs="auto" class="d-flex align-items-center" style="max-height: 22px;">
                    {#if saveButtonVisible}
                        <Button size="sm" disabled={!saveButtonEnabled} on:click={saveModifiedPrice} id={saveButtonId} class="ms-1">
                            <Icon name="save" />
                            <Tooltip target={saveButtonId}>Speichern</Tooltip>
                        </Button>
                    {/if}
                </Col>
            </Row>
        </Container>
    </CardHeader>
    <CardBody>
        <Container class="mb-3 p-0">
            {#if preisFehler}
            <Row class="mb-1 d-flex align-items-center">
                <Alert color="danger">Preisfehler: { preisFehler }</Alert>
            </Row>
            {/if}            
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">KK Min Preis</Col>
                <Col><Input min={0.01} type="number" step="0.01" value={kkMinPrice} disabled={true} /></Col>
                <Col><Input min={0.01} type="number" step="0.01" bind:value={editableViewModel.kkMinPrice} /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">KK Preis</Col>
                <Col><Input min={0.01} type="number" step="0.01" value={kkPrice} disabled={true} /></Col>
                <Col><Input min={0.01} type="number" step="0.01" bind:value={editableViewModel.kkPrice}  /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">KK Max Preis</Col>
                <Col><Input min={0.01} type="number" step="0.01" value={kkMaxPrice} disabled={true} /></Col>
                <Col><Input min={0.01} type="number" step="0.01" bind:value={editableViewModel.kkMaxPrice}  /></Col>
            </Row>                        
        </Container>
        <Container class="mb-3 p-0">
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">BuyboxPreis</Col>
                <Col><Input type="number" step="0.01" value={bbPrice} disabled /></Col>
            </Row>            
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">MinPreis</Col>
                <Col><Input type="number" step="0.01" value={minPrice} disabled /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">Ø-EK</Col>
                <Col><Input type="number" step="0.01" value={ek} disabled /></Col>
            </Row>
        </Container>         
        <Container class="mb-3 p-0">
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">Lowlight</Col>
                <Col><Input checked={isLowlight} type="checkbox" disabled /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">Marge MinPreis</Col>
                <Col><Input type="number" step="0.01" value={margeMinPrice} disabled /></Col>
            </Row>
        </Container>       
        <Container class="mb-3 p-0">
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">Preisposition</Col>
                <Col><Input type="number" step="1" value={preisPosition} disabled /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">X</Col>
                <Col><Input type="number" step="0.01" value={varX} disabled /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">Limitpreis</Col>
                <Col><Input type="number" step="0.01" value={limitPrice} disabled /></Col>
            </Row>
            <Row class="mb-1 d-flex align-items-center">
                <Col sm="3">Ø Angebotspreis <sup>30</sup></Col>
                <Col><Input type="number" step="0.01" value={averageOwnOfferPrice} disabled /></Col>
            </Row>                        
        </Container> 
    </CardBody>
</Card>

<script>

    import { cloneDeep, uniqueId, isEmpty } from 'lodash';
    import { createEventDispatcher, tick } from 'svelte';
    const dispatch = createEventDispatcher();

    import { 
        updateAmazonPricing, 
        configureAmazonPricing
    } from "@/clients/apdbClient";

    import { formatNumberAsTwoDecimal} from '@/utils/format';

    import {
        Alert,
        Card, 
        CardBody,
        CardHeader,
        Input,
        Col,
        Container,
        Row,
        Button,
        Icon,
        Tooltip
    } from '@sveltestrap/sveltestrap';
    
    export let newOrUsed;
    export let queryResultData;

    class ViewModel {
        constructor(kkPrice = null, kkMinPrice = null, kkMaxPrice = null) {
            this.kkPrice = kkPrice;
            this.kkMinPrice = kkMinPrice;
            this.kkMaxPrice = kkMaxPrice;
        }
    }

    let initialViewModel = new ViewModel();
    let editableViewModel = new ViewModel();

    let kkPrice, kkMinPrice, kkMaxPrice;
    let kkFixedMinPrice, kkFixedPrice, kkFixedMaxPrice;
    let minPrice, margeMinPrice, isLowlight, bbPrice, ek, varX, varY, preisPosition, preisFehler;
    let limitPrice, averageOwnOfferPrice;

    $: queryResultData, buildX();

    async function buildX() {

        var src;
        if (queryResultData != null && newOrUsed == "new") {
            src = queryResultData?.amazonSellingPriceNew;
        }
        if (queryResultData != null && newOrUsed == "used") {
            src = queryResultData?.amazonSellingPriceUsed;
        }

        initialViewModel.kkMinPrice = src?.kkFixedMinPrice;
        initialViewModel.kkPrice = src?.kkFixedPrice;
        initialViewModel.kkMaxPrice = src?.kkFixedMaxPrice;
        editableViewModel.kkMinPrice = initialViewModel.kkMinPrice;
        editableViewModel.kkPrice = initialViewModel.kkPrice;
        editableViewModel.kkMaxPrice = initialViewModel.kkMaxPrice;

        kkPrice = formatNumberAsTwoDecimal(src?.kkPrice) || "";
        kkMinPrice = formatNumberAsTwoDecimal(src?.kkMinPrice) || "";
        kkMaxPrice = formatNumberAsTwoDecimal(src?.kkMaxPrice) || "";
        minPrice = formatNumberAsTwoDecimal(src?.minPrice) || "";
        bbPrice = formatNumberAsTwoDecimal(src?.buyboxPrice) || "";
        varX = formatNumberAsTwoDecimal(src?.varX) || "";
        varY = formatNumberAsTwoDecimal(src?.varY) || "";
        limitPrice = formatNumberAsTwoDecimal(src?.limitPrice) || "";
        averageOwnOfferPrice = formatNumberAsTwoDecimal(src?.averageOwnOfferPrice) || "";
        preisPosition = (src?.preisPosition) || "";
        margeMinPrice = formatNumberAsTwoDecimal(src?.margeMinPrice) || "";
        ek = formatNumberAsTwoDecimal(src?.averageEk) || "";
        isLowlight = src?.isLowlight || false;        
        preisFehler = src?.priceError ?? null;
    }

    let isDirty = false;
    $: {
        let a = JSON.stringify(initialViewModel);
        let b = JSON.stringify(editableViewModel);
        isDirty = (a != b);
    };

    let saveButtonId = 'btn-save-' + uniqueId();
    $: saveButtonVisible = isDirty;
    $: saveButtonEnabled = true;

    async function saveModifiedPrice() {
        let asin = queryResultData?.amazonMasterData?.asin;
        let itemCondition = newOrUsed.toUpperCase();
        await configureAmazonPricing(
            asin, 
            itemCondition, 
            editableViewModel.kkPrice, 
            editableViewModel.kkMinPrice, 
            editableViewModel.kkMaxPrice
        );
        /*
        await updateAmazonPricing(
            asin, 
            itemCondition
        );
        */   
        dispatch('data-modified');         
    };

</script>