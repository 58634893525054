<Card class="mb-3">
    <CardHeader>
        Lagerpositionen
    </CardHeader>
    <CardBody>
        <TableWithPaginationAndSort responsive 
            rows={lagerRows} 
            columns={tableColumns} 
            sortDirection="desc" 
            sortByColumn="quantity">
            <div slot="custom-slot-1" let:row={row} let:column={column}>
                { row[column.propertyName] ? salesChannelToText(row[column.propertyName]) : "-" }
            </div>
        </TableWithPaginationAndSort>
    </CardBody>
</Card> 

<script>
    
    import { 
        orderBy, 
        concat 
    } from 'lodash';

    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";

    import { salesChannelToText } from "@/utils/textenums";

    import {
        Card,
        CardBody,
        CardHeader,
    } from '@sveltestrap/sveltestrap';

    export let queryResultData;

    let lagerRows;
    $: {
        let a = (queryResultData?.amazonStockDetails?.items || [])
            .map(s => ({
                store: queryResultData?.amazonStockDetails?.store,
                extItemId: s.extItemId,
                extItemId2: s.extItemId2,
                createdAt: s.createdAt,
                changedAt: s.changedAt,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.instock,
                availableQuantity: s.availableStock,
                unsellableStock: s.unsellableStock,
                active: s.active
            }));        
        let b = (queryResultData?.cheabooStockDetails?.items || [])
            .map(s => ({
                store: queryResultData?.cheabooStockDetails?.store,
                extItemId: s.extItemId,
                extItemId2: s.extItemId2,
                createdAt: s.createdAt,
                changedAt: s.changedAt,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.instock,
                availableQuantity: s.availableStock,
                unsellableStock: s.unsellableStock,
                active: s.active
            }));
        let c = (queryResultData?.buchhandelStockDetails?.items || [])
            .map(s => ({
                store: queryResultData?.buchhandelStockDetails?.store,
                extItemId: s.extItemId,
                extItemId2: s.extItemId2,
                createdAt: s.createdAt,
                changedAt: s.changedAt,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.instock,
                availableQuantity: s.availableStock,
                unsellableStock: s.unsellableStock,
                active: s.active
            })); 
        let d = (queryResultData?.trade123StockDetails?.items || [])
            .map(s => ({
                store: queryResultData?.trade123StockDetails?.store,
                extItemId: s.extItemId,
                extItemId2: s.extItemId2,
                createdAt: s.createdAt,
                changedAt: s.changedAt,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.instock,
                availableQuantity: s.availableStock,
                unsellableStock: s.unsellableStock,
                active: s.active
            })); 
        let e = (queryResultData?.warenweltStockDetails?.items || [])
            .map(s => ({
                store: queryResultData?.warenweltStockDetails?.store,
                extItemId: s.extItemId,
                extItemId2: s.extItemId2,
                createdAt: s.createdAt,
                changedAt: s.changedAt,
                itemCondition: s.itemCondition,
                subCondition: s.subCondition,
                quantity: s.instock,
                availableQuantity: s.availableStock,
                unsellableStock: s.unsellableStock,
                active: s.active
            }));             
        let x = concat(a, b, c, d, e);
        lagerRows = orderBy(x, ["createdAt", "extItemId"], ["desc", "asc"]);
    }
        
    let tableColumns = [
        { displayName: 'Lager', propertyName: 'store', customSlot: '1' },
        { displayName: 'Art.Nr.', propertyName: 'extItemId' },
        { displayName: 'Art.Nr.2', propertyName: 'extItemId2', customSlot: '1' },
        { displayName: 'ItemCondition', propertyName: 'itemCondition' },
        { displayName: 'SubCondition', propertyName: 'subCondition' },        
        { displayName: 'Angelegt', propertyName: 'createdAt', format: 'DATETIME' },
        { displayName: 'Geändert', propertyName: 'changedAt', format: 'DATETIME' },
        { displayName: 'Bestand', propertyName: 'quantity', format: 'NUMBER_0_DECIMALS' } ,
        { displayName: 'verf. Bestand', propertyName: 'availableQuantity', format: 'NUMBER_0_DECIMALS' },
        { displayName: 'nicht verk. Bestand', propertyName: 'unsellableStock', format: 'NUMBER_0_DECIMALS' },
        { displayName: 'aktiv', propertyName: 'active', format: 'BOOLEAN' }            
    ];    

</script>