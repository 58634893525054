import axios from "axios"; 

export const axiosClient = axios.create();

/**
 * makePost
 * @param {String} [uri] - The options for the function.
 * @param {Object} [data] - The options for the function.
 * @param {Object} [options] - The options for the function.
 * @param {boolean} [options.disableLoadingIndicator=true] - Whether to disable the loading indicator. 
 * @param {boolean} [options.disableAxiosAlerts=false] - Whether to disable Axios alerts. Use when you handle errors yourself.
 */
const makePost = async (uri, data, options = {} ) => {

    const defaultOptions = { disableLoadingIndicator: true, disableAxiosAlerts: false };
    const mergedOptions = { ...defaultOptions, ...options };

    return axiosClient
        .post(uri, data, mergedOptions)
        .then((response) => {
            let queryResultData = (response.status == 200) ? response.data : null;
            let queryResultStatus = {
                status: response.status, 
                statusText: response.statusText
            };
            return {queryResultData, queryResultStatus};
        })
        .catch(error => {
            if (!mergedOptions.disableAxiosAlerts) {
                let queryResultData = null;
                let queryResultStatus = {
                    status: error.response?.status, 
                    statusText: error.response?.statusText
                };  
                return {queryResultData, queryResultStatus};  
            } else {
                throw error;
            }
        });

};

/**
 * makeGet
 * @param {String} [uri] - The options for the function.
 * @param {Object} [options] - The options for the function.
 * @param {boolean} [options.disableLoadingIndicator=true] - Whether to disable the loading indicator.
 * @param {boolean} [options.disableAxiosAlerts=false] - Whether to disable Axios alerts. Use when you handle errors yourself.
 */
const makeGet = async (uri, options = {} ) => {

    const defaultOptions = { disableLoadingIndicator: true, disableAxiosAlerts: false };
    const mergedOptions = { ...defaultOptions, ...options };

    return axiosClient
        .get(uri, mergedOptions)
        .then((response) => {
            let queryResultData = (response.status == 200) ? response.data : null;
            let queryResultStatus = {
                status: response.status, 
                statusText: response.statusText
            };
            return {queryResultData, queryResultStatus};
        })
        .catch(error => {
            let queryResultData = null;
            let queryResultStatus = {
                status: error.response?.status, 
                statusText: error.response?.statusText
            };  
            return {queryResultData, queryResultStatus};              
        });

};

export const getProduct = async(ean) => {

    let query = {
        "ean": ean,
        "includeAmazonMasterData": true,
        "includeAmazonLatestNewOffers": true,
        "includeAmazonLatestUsedOffers": true,
        "includeAmazonNotificationPriceHistoryNew": false,
        "includeAmazonNotificationPriceHistoryUsed": false,
        "includeAmazonLatestMwsOffers": true,
        "includeAmazonSellingPriceNew": true,
        "includeAmazonSellingPriceUsed": true,
        "includeAmazonSellingPriceHistoryNew": false,
        "includeAmazonSellingPriceHistoryUsed": false,
        "includeAmazonSalesRank": true,
        "includeAmazonSalesRankDetails": true,
        "includeAmazonStock": true,
        "includeAmazonStockDetails": true,
        "includeAmazonSalesHistogram": true,
        "includeAmazonSalesDetails": true,
        "includeBuchhandelStock": true,
        "includeBuchhandelStockDetails": true,
        "includeBuchhandelSalesHistogram": true,
        "includeBuchhandelSalesDetails": true,    
        "includeTrade123Stock": true,
        "includeTrade123StockDetails": true,
        "includeTrade123SalesHistogram": true,
        "includeTrade123SalesDetails": true,      
        "includeWarenweltStock": true,
        "includeWarenweltStockDetails": true,
        "includeWarenweltSalesHistogram": true,
        "includeWarenweltSalesDetails": true,       
        "includeCheabooStock": true,
        "includeCheabooStockDetails": true,
        "includeCheabooSalesHistogram": true,
        "includeCheabooSalesDetails": true,
        "includeCheabooPriceData": true,
        "includeVlbMasterData": true,
        "includeVlbMediaData": true,
        "includeVlbPriceData": true
    };

    return makePost(`/api/v3/getProduct`, query);

};

export const getProductChart = async(chartName, asin, itemCondition, startInclusive, endExclusive) => {
    
    let query = {
        asin, 
        itemCondition,
        startInclusive,
        endExclusive,
        numSamples: 256
    };

    return makePost(`/api/v3/getProduct/${chartName}Chart`, query);

};

export const updateAmazonPricing = async(asin, itemCondition) => {
    
    let cmd = {
        asin, 
        itemCondition
    };

    return makePost(`/api/v3/updateAmazonPricing`, cmd);

};

export const configureAmazonPricing = async(asin, itemCondition, fixedKkPrice, fixedKkMinPrice, fixedKkMaxPrice) => {
    
    let cmd = {
        asin, 
        itemCondition, 
        fixedKkPrice,
        fixedKkMinPrice,
        fixedKkMaxPrice
    };

    return makePost(`/api/v3/configureAmazonPricing`, cmd);

};

export const searchProduct = async (query) => {
    return makePost(`/api/v3/searchProduct`, query, {disableLoadingIndicator: false});
}

export const getReportList = async () => {
    return makePost(`/api/reports/getReportList`, {});
}

export const getReportDocument = async (id) => {
    return makeGet(`/api/reports/getReportDocument/${id}`, {disableLoadingIndicator: false});
}

export const triggerReport = async (cmd) => {    
    return makePost(`/api/reports/create`, cmd);
}

export const deleteReportById = async (id) => {
    return makePost(`/api/reports/delete`, { id });
}


export const getBatchInputList = async () => {
    return makePost(`/api/batchinput/getJobList`, {});
}

export const deleteBatchInputJobById = async (id) => {
    return makePost(`/api/batchinput/deleteBatchJob`, { id });
}

export const triggerBatchInput = async (cmd) => {
    return makePost(`/api/batchinput/create`, cmd);
}


export const getProductFallbackValues = async (query) => {
    return makePost(`/api/product/fallbacks/getProductFallbackValues`, query );
}

export const putProductFallbackValue = async (cmd) => {
    return makePost(`/api/product/fallbacks/putProductFallbackValue`, cmd);
}


export const getTaskList = async () => {
    return makePost(`/api/tasks/list`, {});
}

export const triggerTask = async (triggerPath, inputComponent) => {
    return makePost(`/api/tasks/${triggerPath}`, inputComponent);
}

export const getTaskInputDocument = async(taskId) => {
    return makeGet(`/api/tasks/read/${taskId}/input`, {disableLoadingIndicator: false});
}

export const getTaskOutputDocument = async(taskId) => {
    return makeGet(`/api/tasks/read/${taskId}/output`, {disableLoadingIndicator: false});
}


export const orderReferencesApi = {
    createNewOrderReference: async (cmd) => {
        return makePost(`/api/orderreferences/v2/createNewOrderReference`, cmd, {disableLoadingIndicator: true, disableAxiosAlerts: true});
    },
    deleteOrderReferenceGroup: async (cmd) => {
        return makePost(`/api/orderreferences/v2/deleteOrderReferenceGroup`, cmd, {disableLoadingIndicator: false});
    },
    getOrderReferenceCacheState: () => {
        return makePost(`/api/orderreferences/v2/getOrderReferenceCacheState`, {}, {disableLoadingIndicator: true, disableAxiosSuccess: true, disableAxiosAlerts: true});
    },
    getOrderReferenceGroups: async (query) => {
        return makePost(`/api/orderreferences/v2/getOrderReferenceGroups`, query, {disableLoadingIndicator: true, disableAxiosSuccess: true, disableAxiosAlerts: true});
    },
    getOrderReferenceGroupSummary: async (query) => {
        return makePost(`/api/orderreferences/v2/getOrderReferenceGroupSummary`, query, {disableLoadingIndicator: true, disableAxiosSuccess: true, disableAxiosAlerts: true});
    },    
    getOrderReferenceDetails: async (query) => { 
        return makePost(`/api/orderreferences/v2/getOrderReferenceDetails`, query, {disableLoadingIndicator: false});
    }
}
